@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: GothamMedium;
  src: url(./GothamMedium.ttf) format("truetype");
}

@font-face {
  font-family: GothamBook;
  src: url(./GothamBook.ttf) format("truetype");
}

@font-face {
  font-family: GothamBold;
  src: url(./GothamBold.ttf) format("truetype");
}

.text-secondary {
  color: #b4b4b4;
}

* {
  font-family: GothamBook;
}

.font-sub-heading {
  font-family: GothamMedium;
}

.font-heading {
  font-family: GothamBold;
}

button.link {
  transition: filter 0.1s linear, transform 0.1s ease-in-out;
}

body {
}

div.inner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* box-shadow: inset 0 0 700px #b4b4b4; */
  overflow: hidden;
  z-index: -1;

  background-color: #fff;
  background-image: url("../images/bg2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.6;
}

@media only screen and (min-width: 768px) {
  div.inner {
    background-image: url("../images/h bg2.svg");
  }
}
